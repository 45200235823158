import { Box, styled } from '@mui/material'

import { StackRow } from '@/components/uiParts/Stack/Row'

export const StyledHeaderRoot = styled('header')(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  zIndex: '99',
  height: '60px',
  backgroundColor:
    // AppConfig().appEnv === 'production'?
    theme.palette.secondary.main,
  // : theme.palette.warning.main,
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
}))

export const StyledHeaderContainer = styled(StackRow)(({ theme }) => ({
  padding: theme.spacing(0, 5),
  width: '100%',
  justifyContent: 'space-between',
}))

export const StyledAppBarItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCurrentPath',
})<{ isCurrentPath: boolean }>(({ isCurrentPath, theme }) => ({
  padding: '8px 12px',
  fontSize: '14px',
  cursor: 'pointer',
  backgroundColor: isCurrentPath
    ? theme.palette.secondary.light
    : 'transparent',
  borderRadius: theme.shape.borderRadius,
  ':hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}))
