import { createTheme } from '@mui/material'
import { common } from '@mui/material/colors'

declare module '@mui/material/styles' {
  interface Palette {
    link: Palette['primary']
    base: Palette['primary']
    border: Palette['primary']
    alert: Palette['primary']
    label: Palette['primary']
    lightGrayishBlue: Palette['primary']
    oldPrimary: Palette['primary']
    softOrange: Palette['primary']
  }
  interface PaletteOptions {
    link?: PaletteOptions['primary']
    base?: PaletteOptions['primary']
    border?: PaletteOptions['primary']
    alert?: PaletteOptions['primary']
    label?: PaletteOptions['primary']
    lightGrayishBlue?: PaletteOptions['primary']
    oldPrimary?: PaletteOptions['primary']
    softOrange?: PaletteOptions['primary']
  }
}

export default createTheme({
  palette: {
    primary: { main: '#5E86E5' },
    secondary: { main: '#2E3E52', light: '#485E7A' },
    error: { main: '#B00020' },
    base: { main: '#F6F7FC', dark: '#F2F3F5', light: '#E6EFFE' },
    link: { main: '#004DCF' },
    border: { main: '#D6D3D0' },
    alert: { main: '#E06242', light: '#fdeded' },
    text: { primary: '#384143', secondary: '#706D65', disabled: '#C1BDB7' },
    label: { main: '#575861' },
    lightGrayishBlue: { main: '#E9F4FD', light: '#F2F1F0' },
    oldPrimary: { main: '#0052cc', contrastText: common.white },
    softOrange: { main: '#E6BF64' },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
})
