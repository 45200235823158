import { Box, styled, ThemeProvider } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/uiParts/Button/BasicButton'
import { USER_ROOT_URL } from '@/constants/constants'
import ErrorIcon from '@/icons/error_icon.svg'
import ErrorImage from '@/icons/error_image.svg'
import UserTheme from '@/styles/user/theme'
import { ErrorPageProps } from '@/types/common'

const StyledWrapper = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.base.main,
  alignItems: 'center',
  color: theme.palette.text.primary,
  columnGap: theme.spacing(5),
}))

const Error500 = ({ resetErrorBoundary }: ErrorPageProps) => {
  const { t } = useTranslation()
  const handleClick = () => {
    if (resetErrorBoundary) resetErrorBoundary()
    window.location.replace(USER_ROOT_URL)
  }

  return (
    <ThemeProvider theme={UserTheme}>
      <StyledWrapper>
        <Box>
          <ErrorImage />
        </Box>
        <Box textAlign="center">
          <ErrorIcon />
          <Box sx={{ fontSize: '40px', fontWeight: 'bold', mb: 4 }}>
            {t('error_message')}
          </Box>
          <Box sx={{ fontSize: '15px', color: 'text.primary', mb: 4 }}>
            {t('contact_support_message')}
          </Box>
          <Button variant="outlined" onClick={handleClick}>
            {t('back_to_top')}
          </Button>
        </Box>
      </StyledWrapper>
    </ThemeProvider>
  )
}

export default Error500
