import { Box } from '@mui/material'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'

import type { MenuProps } from '@/types/common'

import {
  UnauthenticatedHeader,
  Header,
} from '@/components/organisms/user/header'
import {
  StyledMain,
  StyledMainSection,
} from '@/components/templates/user/UserMenu/style'
import { Breadcrumbs } from '@/components/uiParts/BreadCrumb'

export const UserMenu = ({ breadcrumbs, children }: MenuProps) => {
  const router = useRouter()

  return (
    <>
      <Header />
      <StyledMain dashboard={router.pathname === '/user/dashboard'}>
        <StyledMainSection>
          {breadcrumbs && (
            <Box sx={{ mt: 3 }}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </Box>
          )}
          {children}
        </StyledMainSection>
      </StyledMain>
    </>
  )
}

export const UnauthenticatedMenu = ({ children }: { children: ReactNode }) => (
  <>
    <UnauthenticatedHeader />
    <StyledMainSection>{children}</StyledMainSection>
  </>
)
