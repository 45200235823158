import { useRouter } from 'next/router'

import { AppBarItemPopper } from '@/components/organisms/user/header/AppBar/AppBarItemPopper'
import {
  AppBarProps,
  useAppBar,
} from '@/components/organisms/user/header/AppBar/hooks'
import { StyledAppBarItem } from '@/components/organisms/user/header/AppBar/style'
import { StackRow } from '@/components/uiParts/Stack/Row'

export const AppBar = ({ appBarItems }: { appBarItems: AppBarProps[] }) => {
  const router = useRouter()
  const {
    open,
    popperItems,
    anchorEl,
    openPopper,
    stopCloseTimer,
    closePopper,
    isCurrentPath,
  } = useAppBar()

  return (
    <StackRow>
      {appBarItems.map(({ label, path, items }) => {
        const hasChildPaths = items && items.length > 0
        return (
          <StyledAppBarItem
            key={label}
            onClick={() => router.push(path)}
            onMouseOver={(e) => {
              if (!hasChildPaths) return
              openPopper(e, items)
              stopCloseTimer()
            }}
            onMouseOut={() => hasChildPaths && closePopper()}
            role="link"
            isCurrentPath={isCurrentPath({ path, items })}
          >
            {label}
          </StyledAppBarItem>
        )
      })}
      <AppBarItemPopper
        open={open}
        items={popperItems}
        anchorEl={anchorEl}
        stopCloseTimer={stopCloseTimer}
        closePopper={closePopper}
      />
    </StackRow>
  )
}
