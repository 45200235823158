import { Box, styled } from '@mui/material'

export const StyledAppBarItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCurrentPath',
})<{ isCurrentPath: boolean }>(({ isCurrentPath, theme }) => ({
  padding: '8px 12px',
  fontSize: '14px',
  cursor: 'pointer',
  backgroundColor: isCurrentPath
    ? theme.palette.secondary.light
    : 'transparent',
  borderRadius: theme.shape.borderRadius,
  ':hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}))
