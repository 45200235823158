/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `C` - Celsius
* `F` - Fahrenheit
 */
export type ContainerTemperatureTypeEnum = typeof ContainerTemperatureTypeEnum[keyof typeof ContainerTemperatureTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContainerTemperatureTypeEnum = {
  C: 'C',
  F: 'F',
} as const;
