/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `Task` - The common task
* `Sub Task` - The task that has a parent task
 */
export type TaskTypeEnum = typeof TaskTypeEnum[keyof typeof TaskTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskTypeEnum = {
  Task: 'Task',
  Sub_Task: 'Sub Task',
} as const;
