/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type ShipmentsListIncomplete = typeof ShipmentsListIncomplete[keyof typeof ShipmentsListIncomplete];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentsListIncomplete = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
