import React, { ReactElement, createContext, useMemo, useState } from 'react'

interface Context {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

const defaultContext: Context = {
  isLoading: false,
  setIsLoading: () => {},
}

export const LoadingContext = createContext(defaultContext)

export const LoadingProvider = ({ children }: { children: ReactElement }) => {
  const [isLoading, setIsLoading] = useState(false)

  const context = useMemo(
    () => ({
      isLoading,
      setIsLoading,
    }),
    [isLoading, setIsLoading],
  )

  return (
    <LoadingContext.Provider value={context}>
      {children}
    </LoadingContext.Provider>
  )
}
