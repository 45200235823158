import { Box, styled } from '@mui/material'
import Image from 'next/legacy/image'

export const StyledHeader = styled('header')(() => ({
  width: '100%',
  top: 0,
  left: 0,
  position: 'fixed',
  boxSizing: 'border-box',
  height: '60px',
  backgroundColor:
    // AppConfig().appEnv === 'production'
    //   ?
    '#42526e',
  // : theme.palette.warning.main,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 24px',
  boxShadow: '0px 4px 4px rgba(204, 198, 198, 0.25)',
  zIndex: 99,
}))

export const StyledHeaderRightArea = styled(Box)({
  padding: '10px 0',
  height: '58px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 8,
  width: '15%',
})

export const StyledHeaderRightItem = styled(Box)({
  color: '#dbdbdb',
  lineHeight: 'normal',
})

export const StyledImage = styled(Image)({
  textDecoration: 'none',
  borderRadius: '50%',
  background: 'rgb(144, 144, 144)',
})
