/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `USER_COMPANY` - USER_COMPANY
* `FORWARDER` - FORWARDER
* `AIR_FORWARDER` - AIR_FORWARDER
* `GUEST_GROUP` - GUEST_GROUP
 */
export type CollaboratorTypeEnum = typeof CollaboratorTypeEnum[keyof typeof CollaboratorTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorTypeEnum = {
  USER_COMPANY: 'USER_COMPANY',
  FORWARDER: 'FORWARDER',
  AIR_FORWARDER: 'AIR_FORWARDER',
  GUEST_GROUP: 'GUEST_GROUP',
} as const;
