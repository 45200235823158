import { DialogContent } from '@mui/material'
import { useContext } from 'react'

import {
  StyledButton,
  StyledDialog,
  StyledDialogTitle,
} from '@/components/uiParts/Dialog/style'
import { DialogContext, DialogProps } from '@/contexts/Dialog'
import Close from '@/icons/close_btn.svg'

export const Dialog = ({
  close,
  title,
  titleAlign = 'left',
  inner,
  maxWidth = 'md',
}: Omit<DialogProps, 'key'>) => {
  const handleClose = (_: object, reason: string) => {
    if (reason === 'backdropClick') return
    close()
  }

  return (
    <StyledDialog open onClose={handleClose} maxWidth={maxWidth}>
      {title && (
        <StyledDialogTitle sx={{ justifyContent: titleAlign }}>
          {title}
        </StyledDialogTitle>
      )}
      <StyledButton onClick={close}>
        <Close />
      </StyledButton>
      <DialogContent sx={{ p: '0 32px 32px' }}>{inner}</DialogContent>
    </StyledDialog>
  )
}

export const DialogsContainer = () => {
  const { dialogs } = useContext(DialogContext)

  return (
    <>
      {dialogs.map((dialogProps) => (
        <Dialog {...dialogProps} key={dialogProps.key} />
      ))}
    </>
  )
}
