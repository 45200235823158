/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `COMPREHENSIVE` - 包括評価
* `INDIVIDUAL` - 個別評価
* `TBA` - 後報
 */
export type CustomsValuationEnum = typeof CustomsValuationEnum[keyof typeof CustomsValuationEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomsValuationEnum = {
  COMPREHENSIVE: 'COMPREHENSIVE',
  INDIVIDUAL: 'INDIVIDUAL',
  TBA: 'TBA',
} as const;
