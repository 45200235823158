import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { ReactElement, useEffect } from 'react'

import { Loading } from '@/components/uiParts/Loading'
import { ADMIN_ROOT_URL, USER_ROOT_URL } from '@/constants/constants'
import { useUsersMeRetrieve } from '@api'

type PropsType = { is_portrich?: boolean; children: ReactElement }

/**
 * admin認証：admin権限以上の場合childrenを返す。
 * @param children
 * @returns Loading component or children
 */
export const AdminAuth = ({ is_portrich, children }: PropsType) => {
  const { status } = useSession({ required: true })
  const { data: user } = useUsersMeRetrieve({
    swr: { enabled: status === 'authenticated' },
  })
  const router = useRouter()

  useEffect(() => {
    if (!user) return
    // 認証済み && admin権限がない場合、userページにリダイレクト
    if (!user.is_forwarder) router.replace(USER_ROOT_URL)
    if (is_portrich && !user.is_portrich) router.replace(ADMIN_ROOT_URL)
  }, [router, user])

  if (!user) return <Loading />
  if (user.is_forwarder) return children
  return null
}
