/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type ShipmentsListFreightMethod = typeof ShipmentsListFreightMethod[keyof typeof ShipmentsListFreightMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentsListFreightMethod = {
  AIR: 'AIR',
  OCEAN: 'OCEAN',
} as const;
