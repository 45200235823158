import { captureException, setUser } from '@sentry/nextjs'
import axios from 'axios'
import { useSession } from 'next-auth/react'
import { ReactNode, useEffect } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import type { FallbackProps } from 'react-error-boundary'

import Error404 from '@/pages/404'
import Error500 from '@/pages/500'
import { usersMeRetrieve } from '@api'

const onError = (error: unknown) => {
  captureException(error)

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('ErrorBoundary: ', error)
  }
}

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  if (axios.isAxiosError(error)) {
    switch (error.response?.status) {
      case 404:
        return <Error404 resetErrorBoundary={resetErrorBoundary} />
      default:
        return <Error500 resetErrorBoundary={resetErrorBoundary} />
    }
  }
  return <Error500 resetErrorBoundary={resetErrorBoundary} />
}

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  const { status } = useSession()

  useEffect(() => {
    if (status === 'authenticated') {
      usersMeRetrieve()
        .then((user) => {
          setUser({
            id: user.users_id,
            email: user.email,
            username: user.username,
          })
        })
        // eslint-disable-next-line no-console
        .catch(console.warn)
    } else {
      setUser(null)
    }
  }, [status])

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      {children}
    </ReactErrorBoundary>
  )
}
