import { useUsersMeRetrieve } from '@api'
import { Users } from '@api/models'

export type ResourceType =
  | 'DASHBOARD'
  | 'QUOTE_REQUEST'
  | 'PURCHASE'
  | 'SELLING'
  | 'SCHEDULE'
  | 'BOOKING'
  | 'SHIPMENT'
  | 'BILLING'
  | 'ACCOUNTING_REPORT'
  | 'NETWORK'
  | 'TRUCK_CARRIERS'
  | 'USER_COMPANY'
  | 'USER_COMPANY_INFO'
  | 'VESSEL'
  | 'COMMODITY'
  | 'MILESTONE'
  | 'GUEST'
  | 'CHAT'
  | 'DOCUMENT'
  | 'LAND_TRANSPORT'
  | 'CUSTOM'
  | 'TRADE_DOCUMENTS' // A/NやS/Iなどの貿易書類
  | 'QUOTES'
  | 'CONTAINER_TRACKING'
  | 'AIR_FLIGHT_NUMBER'
  | 'AIR_TRANSPORT_SCHEDULE'
  | 'BOOKMARK'
  | 'SHIPPING' // shipment一覧の船舶情報の表示・非表示
  | 'PAY_STATUS'
  | 'PRICE_MANAGEMENT'

export type Operation = 'CREATE' | 'READ' | 'UPDATE'

type UserRole = 'USER' | 'FORWARDER' | 'GUEST' | 'PORTRICH'

export type WithResourceType<T> = T & {
  resourceType: ResourceType
}

const permissions: {
  [r in ResourceType]: { [o in Operation]?: UserRole[] }
} = {
  DASHBOARD: { READ: ['USER', 'PORTRICH', 'GUEST'] },
  QUOTE_REQUEST: { READ: ['USER', 'PORTRICH'] },
  PURCHASE: { READ: ['PORTRICH'] },
  SELLING: { READ: ['PORTRICH'] },
  QUOTES: { READ: ['PORTRICH'] },
  SCHEDULE: { READ: ['PORTRICH'] },
  BOOKING: {
    READ: ['USER', 'PORTRICH', 'GUEST', 'FORWARDER'],
    CREATE: ['USER', 'PORTRICH'],
    UPDATE: ['PORTRICH'],
  },
  SHIPMENT: {
    READ: ['USER', 'PORTRICH', 'GUEST', 'FORWARDER'],
    UPDATE: ['USER', 'PORTRICH'],
  },
  BILLING: { READ: ['USER', 'PORTRICH'] },
  ACCOUNTING_REPORT: { READ: ['PORTRICH'] },
  NETWORK: { READ: ['USER', 'PORTRICH'] },
  TRUCK_CARRIERS: { READ: ['PORTRICH'] },
  USER_COMPANY: {
    READ: ['PORTRICH', 'FORWARDER'],
    UPDATE: ['PORTRICH'],
    CREATE: ['PORTRICH'],
  },
  USER_COMPANY_INFO: {
    READ: ['PORTRICH'],
    UPDATE: ['PORTRICH'],
    CREATE: ['PORTRICH'],
  },
  VESSEL: { READ: ['PORTRICH'] },
  COMMODITY: { READ: ['PORTRICH'] },
  MILESTONE: { READ: ['PORTRICH'] },
  GUEST: { READ: ['USER', 'PORTRICH'] },
  CHAT: { READ: ['USER', 'PORTRICH', 'GUEST', 'FORWARDER'] },
  DOCUMENT: { READ: ['USER', 'PORTRICH', 'GUEST', 'FORWARDER'] },
  LAND_TRANSPORT: { READ: ['USER', 'PORTRICH'] },
  CUSTOM: { READ: ['USER', 'PORTRICH'] },
  TRADE_DOCUMENTS: {
    READ: ['PORTRICH'],
    CREATE: ['PORTRICH'],
    UPDATE: ['PORTRICH'],
  },
  CONTAINER_TRACKING: {
    READ: ['PORTRICH', 'FORWARDER', 'USER', 'GUEST'],
    UPDATE: ['PORTRICH', 'FORWARDER'],
  },
  AIR_FLIGHT_NUMBER: {
    READ: ['PORTRICH'],
    CREATE: ['PORTRICH'],
  },
  AIR_TRANSPORT_SCHEDULE: {
    READ: ['PORTRICH'],
    CREATE: ['PORTRICH'],
  },
  BOOKMARK: { READ: ['USER'] },
  SHIPPING: { READ: ['FORWARDER', 'PORTRICH'] },
  PAY_STATUS: { READ: ['PORTRICH'] },
  PRICE_MANAGEMENT: {
    READ: ['PORTRICH'],
    CREATE: ['PORTRICH'],
    UPDATE: ['PORTRICH'],
  },
}

function hasPermission(
  role: UserRole,
  resourceType: ResourceType,
  operation: Operation = 'READ',
) {
  return permissions[resourceType][operation]?.includes(role)
}

function getUserRole(user: Users) {
  if (user.is_portrich) return 'PORTRICH'
  if (user.is_forwarder) return 'FORWARDER'
  if (user.is_user) return 'USER'
  if (user.is_guest) return 'GUEST'
  return undefined
}

export const usePermission = () => {
  const { data: user } = useUsersMeRetrieve()
  const role = user ? getUserRole(user) : undefined

  const permitted = (resourceType: ResourceType, operation: Operation) =>
    role && hasPermission(role, resourceType, operation)

  const filter = <T>(items: WithResourceType<T>[]): T[] =>
    items
      .filter((item) => permitted(item.resourceType, 'READ'))
      .map((item) => {
        const { resourceType, ...origin } = item
        return origin as T
      })

  return { permitted, filter }
}
