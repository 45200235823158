/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `SALES` - Sales
* `COST` - Cost
 */
export type CategoryEnum = typeof CategoryEnum[keyof typeof CategoryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoryEnum = {
  SALES: 'SALES',
  COST: 'COST',
} as const;
