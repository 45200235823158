/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `1` - port (for any kind of waterborne transport)
* `2` - Rail Terminal
* `3` - Road Terminal
* `4` - Airport
* `5` - Postal Exchange Office
* `6` - ICD or 'Dry Port', 'Inland Clearance Terminal', etc.
 */
export type CategoriesEnum = typeof CategoriesEnum[keyof typeof CategoriesEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoriesEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
} as const;
