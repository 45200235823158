/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `CHARTER` - チャーター
* `CHARTER_NOT_SAME_DAY` - チャーター(積み置き)
* `CHARTER_SAME_DAY` - チャーター(当日)
* `CONSOLIDATION` - 混載
* `DRAYAGE` - ドレージ
 */
export type TransportMethodEnum = typeof TransportMethodEnum[keyof typeof TransportMethodEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransportMethodEnum = {
  CHARTER: 'CHARTER',
  CHARTER_NOT_SAME_DAY: 'CHARTER_NOT_SAME_DAY',
  CHARTER_SAME_DAY: 'CHARTER_SAME_DAY',
  CONSOLIDATION: 'CONSOLIDATION',
  DRAYAGE: 'DRAYAGE',
} as const;
