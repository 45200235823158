import {
  createContext,
  useMemo,
  PropsWithChildren,
  useState,
  ReactNode,
  Dispatch,
  DispatchWithoutAction,
} from 'react'

export interface DialogProps {
  key: string
  close: DispatchWithoutAction
  inner?: ReactNode
  title?: string
  titleAlign?: 'left' | 'center'
  maxWidth?: 'xl' | 'lg' | 'md'
}

interface Context {
  dialogs: DialogProps[]
  open: Dispatch<
    {
      render?: ({ close }: { close: DispatchWithoutAction }) => ReactNode
    } & Pick<DialogProps, 'title' | 'titleAlign' | 'maxWidth'>
  >
}

const defaultContext: Context = {
  dialogs: [],
  open: () => {},
}

export const DialogContext = createContext(defaultContext)

export const DialogContextProvider = ({ children }: PropsWithChildren) => {
  const [dialogs, setDialogs] = useState<DialogProps[]>([])

  const open: Context['open'] = (options) => {
    const key = String(new Date().getTime())
    const close = () => {
      const filtered = dialogs.filter((dialog) => dialog.key !== key)
      setDialogs(filtered)
    }

    const dialog: DialogProps = {
      ...options,
      key,
      close,
      inner: options.render ? options.render({ close }) : undefined,
    }
    setDialogs([...dialogs, dialog])
  }

  const context = useMemo(
    () => ({
      dialogs,
      open,
    }),
    [dialogs],
  )

  return (
    <DialogContext.Provider value={context}>{children}</DialogContext.Provider>
  )
}
