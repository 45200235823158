import { DialogTitle, Dialog, Button, styled } from '@mui/material'

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.base?.main || 'white',
    position: 'relative',
  },
}))

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '16px 32px',
  textAlign: 'left',
  height: '72px',
  display: 'flex',
  alignItems: 'center',
}))

export const StyledButton = styled(Button)(() => ({
  position: 'absolute',
  right: '32px',
  top: '16px',
  padding: 0,
  minWidth: '40px',
  borderRadius: '50%',
}))
