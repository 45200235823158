/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `D/N` - D/N
* `C/N` - C/N
 */
export type ForwarderInvoiceTypeEnum = typeof ForwarderInvoiceTypeEnum[keyof typeof ForwarderInvoiceTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ForwarderInvoiceTypeEnum = {
  'D/N': 'D/N',
  'C/N': 'C/N',
} as const;
