import { Alert, Snackbar as MuiSnackbar } from '@mui/material'
import { useContext, useEffect, useState } from 'react'

import { SnackbarContext } from '@/contexts/Snackbar'

export const Snackbar = () => {
  const { show, message, severity, snackbarDispatch } =
    useContext(SnackbarContext)
  const [open, setOpen] = useState(show)

  const handleClickClose = () => {
    setOpen(false)
    setTimeout(() => snackbarDispatch({ type: 'HIDE_SNACKBAR' }), 500)
  }

  useEffect(() => {
    setOpen(show)
  }, [show])

  return (
    <MuiSnackbar
      open={open}
      onClose={handleClickClose}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        elevation={5}
        variant="filled"
        onClose={handleClickClose}
        severity={severity}
        sx={{ width: '100%', fontSize: '14px' }}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  )
}
