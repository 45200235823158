/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `1` - Prepaid
* `2` - Collect
 */
export type FreightEnum = typeof FreightEnum[keyof typeof FreightEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FreightEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;
