import { Box, styled } from '@mui/material'

export const StyledMain = styled('main')<{
  dashboard?: boolean
}>(({ theme, dashboard }) => ({
  backgroundColor: theme.palette.base.main,
  display: 'flex',
  justifyContent: 'center',
  padding: dashboard ? '60px 0' : '60px 40px 16px',
  overflow: 'auto',
  minHeight: '100vh',
}))

export const StyledMainSection = styled(Box)({
  width: '100%',
  maxWidth: '1700px',
  minWidth: '1120px',
})
