/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `JPY` - 日本円
* `EUR` - ユーロ
* `CNY` - 中国人民元
* `HKD` - 香港ドル
* `INR` - インド・ルピー
* `SGD` - シンガポール・ドル
* `AUD` - オーストラリア・ドル
* `SEK` - スウェーデン・クローナ
* `NOK` - ノルウェー・クローネ
* `TRY` - トルコ・リラ
* `ZAR` - 南アフリカ・ランド
* `MXN` - メキシコ・ペソ
* `USD` - 米ドル
* `GBP` - 英ポンド
* `KRW` - 韓国ウォン
* `TWD` - 台湾ドル
* `IDR` - インドネシア・ルピア
* `THB` - タイ・バーツ
* `NZD` - ニュージーランド・ドル
* `DKK` - デンマーク・クローネ
* `CHF` - スイス・フラン
* `RUB` - ロシア・ルーブル
* `CAD` - カナダ･ドル
* `BRL` - ブラジル・レアル
* `VND` - ベトナムドン
 */
export type CurrencyCodeEnum = typeof CurrencyCodeEnum[keyof typeof CurrencyCodeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CurrencyCodeEnum = {
  JPY: 'JPY',
  EUR: 'EUR',
  CNY: 'CNY',
  HKD: 'HKD',
  INR: 'INR',
  SGD: 'SGD',
  AUD: 'AUD',
  SEK: 'SEK',
  NOK: 'NOK',
  TRY: 'TRY',
  ZAR: 'ZAR',
  MXN: 'MXN',
  USD: 'USD',
  GBP: 'GBP',
  KRW: 'KRW',
  TWD: 'TWD',
  IDR: 'IDR',
  THB: 'THB',
  NZD: 'NZD',
  DKK: 'DKK',
  CHF: 'CHF',
  RUB: 'RUB',
  CAD: 'CAD',
  BRL: 'BRL',
  VND: 'VND',
} as const;
