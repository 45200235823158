import { Box, Stack, styled, ThemeProvider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/uiParts/Button/BasicButton'
import { USER_ROOT_URL } from '@/constants/constants'
import ErrorImage from '@/icons/error_404.svg'
import ErrorIcon from '@/icons/page_not_found.svg'
import UserTheme from '@/styles/user/theme'
import { ErrorPageProps } from '@/types/common'

const StyledWrapper = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.base.main,
  alignItems: 'center',
  columnGap: theme.spacing(8),
}))

const Error404 = ({ resetErrorBoundary }: ErrorPageProps) => {
  const { t } = useTranslation()
  const handleClick = () => {
    if (resetErrorBoundary) resetErrorBoundary()
    window.location.replace(USER_ROOT_URL)
  }

  return (
    <ThemeProvider theme={UserTheme}>
      <StyledWrapper>
        <Stack direction="column" spacing={4} textAlign="center">
          <Box>
            <ErrorIcon />
          </Box>
          <Typography
            sx={{
              fontSize: '128px',
              fontWeight: 'bold',
              color: 'primary.main',
              lineHeight: 1,
              fontFamily: '"Montserrat", sans-serif',
            }}
          >
            404
          </Typography>
          <Box
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
              color: 'secondary.main',
            }}
          >
            {t('cannot_be_found_the_page')}
          </Box>
          <Box
            sx={{ width: '377px', fontSize: '15px', color: 'text.secondary' }}
          >
            {t('it_may_be_temporarily_inaccessible')}
          </Box>
          <Box>
            <Button variant="outlined" onClick={handleClick}>
              {t('back_to_top')}
            </Button>
          </Box>
        </Stack>
        <Box>
          <ErrorImage />
        </Box>
      </StyledWrapper>
    </ThemeProvider>
  )
}

export default Error404
