type DecodeProps = {
  user_id: string
  token_type: string
  exp: number
  iat: number
  jti: string
}

export const decode = (token: string): DecodeProps => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const decodedData = Buffer.from(base64, 'base64').toString('utf-8')
    return JSON.parse(decodedData) as DecodeProps
  } catch (err) {
    throw new Error('Invalid JWT')
  }
}
