import { Box, Paper, styled } from '@mui/material'

export const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '6px',
  overflow: 'hidden',
  border: '1px solid',
  borderColor: theme.palette.base.main,
}))

export const StyledLinkBox = styled(Box)(({ theme }) => ({
  padding: '16px 32px 16px 12px',
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  boxShadow: '4px',
  cursor: 'pointer',
  ':before': {
    content: '""',
    display: 'block',
    width: '11px',
    height: '4px',
  },
  ':hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    ':before': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))
