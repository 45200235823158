/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `ORIGINAL` - Original
* `WAYBILL` - Waybill
* `SURRENDER` - Surrender
 */
export type MblTypeEnum = typeof MblTypeEnum[keyof typeof MblTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MblTypeEnum = {
  ORIGINAL: 'ORIGINAL',
  WAYBILL: 'WAYBILL',
  SURRENDER: 'SURRENDER',
} as const;
