import { useRouter } from 'next/router'
import { useRef, useState, MouseEvent } from 'react'

export type AppBarItemProps = { label: string; path: string }

export type AppBarProps = {
  label: string
  path: string
  items?: AppBarItemProps[]
}

export const useAppBar = () => {
  const router = useRouter()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const [popperItems, setPopperItems] = useState<AppBarItemProps[]>([])

  const timer = useRef<null | NodeJS.Timeout>(null)
  const POPPER_CLOSE_DURATION_MS = 300

  const isCurrentPath = ({
    path,
    items,
  }: Pick<AppBarProps, 'path' | 'items'>) => {
    const currentPath = router?.pathname
    return (
      currentPath === path ||
      (items && items.some((item) => item.path === currentPath)) ||
      false
    )
  }

  /**
   * Popperを閉じるまでのsetTimeoutをクリア
   * Hoverが外れてからも、再度Popperが出るアクションを行った際にTimerをクリアして、閉じないよう制御する
   */
  const stopCloseTimer = () => {
    if (!timer.current) return
    clearInterval(timer.current)
    timer.current = null
  }

  const openPopper = (
    e: MouseEvent<HTMLDivElement>,
    items: AppBarItemProps[],
  ) => {
    stopCloseTimer()
    setAnchorEl(e.currentTarget)
    setOpen(true)
    setPopperItems(items)
  }

  /**
   * POPPER_CLOSEDURATION_MSミリ秒後に閉じる
   * この間にstopCloseTimerが走ると、中断する。
   */
  const closePopper = () => {
    const closeTimer = setTimeout(() => {
      setOpen(false)
    }, POPPER_CLOSE_DURATION_MS)
    timer.current = closeTimer
  }

  return {
    open,
    popperItems,
    anchorEl,
    openPopper,
    stopCloseTimer,
    closePopper,
    isCurrentPath,
  }
}
