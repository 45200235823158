/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `0` - FCL
* `1` - LCL
 */
export type ShippingMethodEnum = typeof ShippingMethodEnum[keyof typeof ShippingMethodEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShippingMethodEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
