import LanguageIcon from '@mui/icons-material/Language'
import { FormControl, Select, styled, Box } from '@mui/material'

// TODO: 翻訳機能の準備が整ったらこのコンポーネントを削除する.
export const InvisibleBox = styled(Box)({
  display: process.env.NODE_ENV === 'production' ? 'none' : 'unset',
})

export const StyledFormControl = styled(FormControl)({
  margin: 1,
  minWidth: 120,
})

export const StyledSelect = styled(Select)({
  color: 'white',
  width: '100px',
  fontSize: '14px',
})

export const StyledLanguageIcon = styled(LanguageIcon)({
  color: 'white',
})
