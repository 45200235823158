/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `NOT STARTED` - Not Started
* `STARTED` - Started
* `COMPLETED` - Complete
* `SKIPPED` - Skipped
 */
export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusEnum = {
  NOT_STARTED: 'NOT STARTED',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  SKIPPED: 'SKIPPED',
} as const;
