import { Box, styled } from '@mui/material'

export const StyledMain = styled('main')({
  display: 'flex',
  paddingTop: '60px',
})

export const StyledMainSection = styled(Box)({
  width: 'calc((100vw - 70px) - (100vw - 100%))',
  padding: '0 16px 16px',
  height: '100%',
  marginLeft: '70px',
})
