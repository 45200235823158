/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type PurchaseManagementsAirTransportListUrgencyLevel = typeof PurchaseManagementsAirTransportListUrgencyLevel[keyof typeof PurchaseManagementsAirTransportListUrgencyLevel];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseManagementsAirTransportListUrgencyLevel = {
  DEFERRED: 'DEFERRED',
  ECONOMY: 'ECONOMY',
  EXPRESS: 'EXPRESS',
} as const;
