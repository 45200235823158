import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@mui/material'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

export type BreadcrumbsProps = {
  breadcrumbs?: {
    text: string // TODO: 翻訳機能が完成したら削除する.
    translateKey?: string
    path?: string
  }[]
}

const BreadcrumbsLinks = ({ breadcrumbs }: BreadcrumbsProps) => {
  const { t } = useTranslation()

  return breadcrumbs?.map((row) => {
    const text = row.translateKey ? t(row.translateKey) : row.text
    if (typeof row.path !== 'undefined') {
      return (
        <Link key={text} href={row.path} style={{ color: '#5E86E5' }}>
          {text}
        </Link>
      )
    }
    return (
      <Typography key={text} color="text.primary">
        {text}
      </Typography>
    )
  })
}

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const breadcrumbsLinkList = BreadcrumbsLinks({ breadcrumbs })
  return (
    <MUIBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbsLinkList}
    </MUIBreadcrumbs>
  )
}
