/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `DRAFT` - Draft
* `COPY` - Copy
* `ORIGINAL` - Original
* `SURRENDER` - Surrender
* `TELEX RELEASE` - Telex Release
* `WAYBILL DRAFT` - Waybill Draft
* `WAYBILL ORIGINAL` - Waybill Original
 */
export type BlTypeEnum = typeof BlTypeEnum[keyof typeof BlTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BlTypeEnum = {
  DRAFT: 'DRAFT',
  COPY: 'COPY',
  ORIGINAL: 'ORIGINAL',
  SURRENDER: 'SURRENDER',
  TELEX_RELEASE: 'TELEX RELEASE',
  WAYBILL_DRAFT: 'WAYBILL DRAFT',
  WAYBILL_ORIGINAL: 'WAYBILL ORIGINAL',
} as const;
