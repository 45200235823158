import { Box } from '@mui/material'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { useSideBar } from '@/components/organisms/admin/common/sidebar/hooks'
import {
  StyledAdminSidebar,
  StyledAdminSidebarMenuArea,
  StyledAdminSidebarMenuItem,
  StyledIcon,
  StyledValue,
} from '@/components/organisms/admin/common/sidebar/style'
import { usePermission } from '@/permissions'

export const AdminSidebar = () => {
  const router = useRouter()
  const { filter } = usePermission()
  const { adminSidebarItem } = useSideBar()

  const handleClickNavigate = useCallback(
    (path: string) => {
      if (router.pathname !== path) {
        router.push({
          pathname: path,
        })
      }
    },
    [router.query],
  )
  const isActive = (basePath: string) => {
    // 現在のパスがbasePathで始まるか、完全に一致する場合にtrueを返す
    // 例外: 現在のパスがbasePathよりも長く、basePathの直後にスラッシュが来ない場合はfalseを返す（部分一致を避ける）
    return (
      router.pathname === basePath ||
      (router.pathname.startsWith(basePath) &&
        router.pathname.charAt(basePath.length) === '/')
    )
  }

  return (
    <StyledAdminSidebar>
      <StyledAdminSidebarMenuArea>
        {filter(adminSidebarItem).map((value, index) => (
          <StyledAdminSidebarMenuItem
            isActive={isActive(value.basePath)}
            onClick={() => handleClickNavigate(value.path || value.basePath)}
            key={index}
          >
            <Box
              display="flex"
              flexWrap="wrap"
              width="40px"
              justifyContent="center"
            >
              <StyledIcon className="material-icons">{value.icon}</StyledIcon>
              <StyledValue mt="-4px">
                {value.label.split('\n').map((text) => (
                  <div key={text}>
                    {text}
                    <br />
                  </div>
                ))}
              </StyledValue>
            </Box>
          </StyledAdminSidebarMenuItem>
        ))}
      </StyledAdminSidebarMenuArea>
    </StyledAdminSidebar>
  )
}
