import { MenuItem, SelectChangeEvent } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next' // NOTE: サーバーサイドレンダリングを行っていないため react-i18next を使用している(Next.js 向けに next-i18next というものがある).

import {
  StyledLanguageIcon,
  StyledFormControl,
  StyledSelect,
  InvisibleBox,
} from '@/components/organisms/common/Language/style'
import { StackRow } from '@/components/uiParts/Stack/Row'

const options = [
  { value: 'en', label: 'English' },
  { value: 'ja', label: '日本語' },
]

// NOTE: 挙動チェックのためのメニューアイテム. 本番環境では表示しない.
// > HINT: For easy testing—setting lng to 'cimode' will cause the t function to always return the key.
// > cf. https://www.i18next.com/overview/api#changelanguage
// > cf. https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
if (process.env.NODE_ENV !== 'production') {
  options.push({ value: 'cimode', label: 'cimode' })
}

export const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation()

  // TODO: 初回リリース時, i18next による翻訳機能は提供しないので固定で "ja" をセットしている. 翻訳機能が提供される際には useEffect ごと削除する.
  useEffect(() => {
    i18n.changeLanguage('ja')
  }, [])

  // NOTE: 言語管理は localStorage で行うので router.push() などの処理は行っていない.
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    i18n.changeLanguage(event.target.value as string)
  }

  return (
    <InvisibleBox>
      <StackRow title={t('language')}>
        <StyledLanguageIcon />
        <StyledFormControl variant="standard">
          <StyledSelect value={i18n.language} onChange={handleChange}>
            {/* ISO 639-1 の並び順を意識している */}
            {options.map((menu) => (
              <MenuItem key={menu.value} value={menu.value}>
                {menu.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledFormControl>
      </StackRow>
    </InvisibleBox>
  )
}
