import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { ReactElement, useEffect } from 'react'

import { Loading } from '@/components/uiParts/Loading'
import { ADMIN_ROOT_URL } from '@/constants/constants'
import { useUsersMeRetrieve } from '@api'

export const UserAuth = ({ children }: { children: ReactElement }) => {
  const { status } = useSession({ required: true })
  const { data: user } = useUsersMeRetrieve({
    swr: { enabled: status === 'authenticated' },
  })
  const router = useRouter()

  useEffect(() => {
    if (!user) return
    // フォワーダー & Portrichではない（他社代理店）場合、adminにリダイレクト
    if (user.is_forwarder && !user.is_portrich) router.replace(ADMIN_ROOT_URL)
  }, [router, user])

  if (!user) return <Loading />
  if (user.is_forwarder && !user.is_portrich) return null
  if (user.is_user || user.is_guest) return children
  return null
}
