/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `CUSTOMS_BROKER` - 貴社手配
* `PORTRICH` - ポートリッチ
* `USER_COMPANY` - 荷主手配
 */
export type LandTransportArrangementTypeEnum = typeof LandTransportArrangementTypeEnum[keyof typeof LandTransportArrangementTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LandTransportArrangementTypeEnum = {
  CUSTOMS_BROKER: 'CUSTOMS_BROKER',
  PORTRICH: 'PORTRICH',
  USER_COMPANY: 'USER_COMPANY',
} as const;
