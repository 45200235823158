/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `CY` - CY通関
* `CFS` - CFS通関
* `WAREHOUSE` - Warehouse通関
* `AIR` - Air通関
 */
export type CustomsMethodEnum = typeof CustomsMethodEnum[keyof typeof CustomsMethodEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomsMethodEnum = {
  CY: 'CY',
  CFS: 'CFS',
  WAREHOUSE: 'WAREHOUSE',
  AIR: 'AIR',
} as const;
