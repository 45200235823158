import { AxiosError } from 'axios'

/** HTTPステータスコードが異常系エラーコードかどうか */
export const isHttpStatusError = (status: string | number | undefined) =>
  typeof status === 'undefined' ||
  // 通信エラーの場合、0になる
  Number(status) === 0 ||
  Number(status) === 404 ||
  Number(status) >= 500

export const isClientError = (err: unknown) => {
  if (err instanceof AxiosError) {
    const status = Number(err.response?.status)
    return status >= 400 && status < 500
  }

  return false
}
