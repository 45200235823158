import {
  createContext,
  useReducer,
  Dispatch,
  useMemo,
  ReactElement,
} from 'react'

type SnackBarStateType = {
  show: boolean
  message?: string
  severity: 'error' | 'warning' | 'info' | 'success'
}

type SnackBarActionType = {
  type?: 'HIDE_SNACKBAR'
  message?: string
  severity?: 'error' | 'warning' | 'info' | 'success'
}

const initialState: SnackBarStateType = {
  show: false,
  message: '',
  severity: 'info',
}
const defaultDispatch: Dispatch<SnackBarActionType> = () => initialState
const defaultValue = { ...initialState, snackbarDispatch: defaultDispatch }

export const SnackbarContext = createContext(defaultValue)

const reducer = (state: SnackBarStateType, action: SnackBarActionType) => {
  switch (action.type) {
    case 'HIDE_SNACKBAR': {
      return initialState
    }
    default:
      return {
        ...state,
        show: true,
        message:
          action.message || (action.severity === 'success' && 'success') || '',
        severity: action.severity || 'info',
      }
  }
}

/**
 * reducerとcontextを子コンポーネントに渡す
 * @param children 子コンポーネント
 * @returns 子コンポーネント
 */
export const SnackbarContextProvider = ({
  children,
}: {
  children: ReactElement
}) => {
  const [{ show, message, severity }, snackbarDispatch] = useReducer(
    reducer,
    initialState,
  )

  const context = useMemo(
    () => ({
      show,
      message,
      severity,
      snackbarDispatch,
    }),
    [show, message, severity, snackbarDispatch],
  )

  return (
    <SnackbarContext.Provider value={context}>
      {children}
    </SnackbarContext.Provider>
  )
}
