/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `OCEAN` - 船舶
* `AIR` - 航空
 */
export type FreightMethodTypeEnum = typeof FreightMethodTypeEnum[keyof typeof FreightMethodTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FreightMethodTypeEnum = {
  OCEAN: 'OCEAN',
  AIR: 'AIR',
} as const;
