import { useSession } from 'next-auth/react'
import { useEffect } from 'react'

export const RefreshTokenHandler = (props: {
  setInterval: React.Dispatch<number>
}) => {
  const { setInterval } = props
  const { data: session } = useSession()

  useEffect(() => {
    if (session) {
      // TODO: accessTokenExpiryをAPIから取得できるようになったら変更する
      const timeRemaining = 4 * 60
      // 参考
      // const timeRemaining = Math.round(
      //   (session.accessTokenExpiry - 30 * 60 * 1000 - Date.now()) / 1000,
      // )

      setInterval(timeRemaining > 0 ? timeRemaining : 0)
    }
  }, [session, setInterval])

  return null
}
