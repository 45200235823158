/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type ShippingSchedulesSearchRetrieveStartDateType = typeof ShippingSchedulesSearchRetrieveStartDateType[keyof typeof ShippingSchedulesSearchRetrieveStartDateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShippingSchedulesSearchRetrieveStartDateType = {
  arrival: 'arrival',
  departure: 'departure',
} as const;
