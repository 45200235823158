/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type QuotesAirTransportListUrgencyLevel = typeof QuotesAirTransportListUrgencyLevel[keyof typeof QuotesAirTransportListUrgencyLevel];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuotesAirTransportListUrgencyLevel = {
  DEFERRED: 'DEFERRED',
  ECONOMY: 'ECONOMY',
  EXPRESS: 'EXPRESS',
} as const;
