/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type ShipmentsListBookmark = typeof ShipmentsListBookmark[keyof typeof ShipmentsListBookmark];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentsListBookmark = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
