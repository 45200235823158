/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `ADVANCE` - 立替
* `ADVANCE_PORTRICH` - 立替(弊社事前入金)
* `REALTIME` - リアルタイム
* `DEFERRED` - 延納
* `MULTIPLE` - マルチペイメント
* `DIRECT` - 直納
* `TBA` - 後報
 */
export type DutyPaymentTypeEnum = typeof DutyPaymentTypeEnum[keyof typeof DutyPaymentTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DutyPaymentTypeEnum = {
  ADVANCE: 'ADVANCE',
  ADVANCE_PORTRICH: 'ADVANCE_PORTRICH',
  REALTIME: 'REALTIME',
  DEFERRED: 'DEFERRED',
  MULTIPLE: 'MULTIPLE',
  DIRECT: 'DIRECT',
  TBA: 'TBA',
} as const;
