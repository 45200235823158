/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `0` - 2軸
* `1` - 3軸
 */
export type ChassisEnum = typeof ChassisEnum[keyof typeof ChassisEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChassisEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
