/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `DEFERRED` - 遅延配送
* `ECONOMY` - 通常配送
* `EXPRESS` - 速達
 */
export type UrgencyLevelEnum = typeof UrgencyLevelEnum[keyof typeof UrgencyLevelEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UrgencyLevelEnum = {
  DEFERRED: 'DEFERRED',
  ECONOMY: 'ECONOMY',
  EXPRESS: 'EXPRESS',
} as const;
