import {
  createContext,
  Dispatch,
  ReactElement,
  useMemo,
  useReducer,
} from 'react'

type FormStateType = Record<string, unknown>
type FormActionType = {
  type: 'INPUT' | 'REMOVE'
  inputData?: FormStateType
}

const initialState: FormStateType = {}
const defaultDispatch: Dispatch<FormActionType> = () => initialState
const defaultValue = { ...initialState, dispatch: defaultDispatch }

/** Formの入力情報を保持する */
export const FormContext = createContext(defaultValue)

const reducer = (state: FormStateType, action: FormActionType) => {
  switch (action.type) {
    case 'INPUT': {
      const newState = {
        ...action.inputData,
      }
      return newState
    }
    case 'REMOVE': {
      return {}
    }
    default:
      // TODO:エラー対応に変更
      return state
  }
}

/**
 * reducerとcontextを子コンポーネントに渡す
 * @param children 子コンポーネント
 * @returns 子コンポーネント
 */
export const FormContextProvider = ({
  children,
}: {
  children: ReactElement
}) => {
  const [inputData, dispatch] = useReducer(reducer, initialState)

  const context = useMemo(
    () => ({
      inputData,
      dispatch,
    }),
    [inputData, dispatch],
  )

  return <FormContext.Provider value={context}>{children}</FormContext.Provider>
}
