/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `FOOD` - 食品
* `ANIMAL` - 動検
* `PLANT` - 植防
* `PHARMACEUTICAL` - 薬機
* `OTHER` - その他
 */
export type OtherLawEnum = typeof OtherLawEnum[keyof typeof OtherLawEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OtherLawEnum = {
  FOOD: 'FOOD',
  ANIMAL: 'ANIMAL',
  PLANT: 'PLANT',
  PHARMACEUTICAL: 'PHARMACEUTICAL',
  OTHER: 'OTHER',
} as const;
