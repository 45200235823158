/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `EXPORT` - 輸出
* `IMPORT` - 輸入
* `SHIP_BACK` - 積戻
 */
export type CustomsSectionEnum = typeof CustomsSectionEnum[keyof typeof CustomsSectionEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomsSectionEnum = {
  EXPORT: 'EXPORT',
  IMPORT: 'IMPORT',
  SHIP_BACK: 'SHIP_BACK',
} as const;
