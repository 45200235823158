/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `MAN` - 男性
* `WOMAN` - 女性
 */
export type GenderEnum = typeof GenderEnum[keyof typeof GenderEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GenderEnum = {
  MAN: 'MAN',
  WOMAN: 'WOMAN',
} as const;
