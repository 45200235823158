import NotificationsIcon from '@mui/icons-material/Notifications'
import {
  Badge,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Popover,
} from '@mui/material'
import { Dispatch, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useChatRoomsUnreadList } from '@api'
import { UnreadChatRoom } from '@api/models'

const NotificationList = ({
  items,
  onSelect,
}: {
  items: UnreadChatRoom[]
  onSelect: Dispatch<UnreadChatRoom>
}) => {
  const { t } = useTranslation()
  // メッセージ数ではなく、未読メッセージが含まれるチャットルームの数
  // 厳密な未読メッセージ数が必要ならAPI側で対応する
  const totalMessageCount = items.length

  return (
    <Box minWidth={300} maxHeight="80vh">
      <Box px={2} py={1}>
        <Box fontWeight="bold" fontSize={16}>
          {t('notice')}
        </Box>
        {items.length === 0 ? (
          <Box>{t('no_unread_messages')}</Box>
        ) : (
          <Box>
            {t('message.unread_messages', { count: totalMessageCount })}
          </Box>
        )}
      </Box>
      <Divider />
      {items.map((item) => (
        <MenuItem key={item.id} onClick={() => onSelect(item)}>
          <Box>
            <Box fontWeight="bold" fontSize={14}>
              {item.portrich_case_number}
            </Box>
            <Box>
              {/* フォワーダーユーザーの場合のみ表示される */}
              {item.user_company_name}
            </Box>
            <Box>
              {item.place_of_receipt_name}→{item.place_of_delivery_name}
            </Box>
            <Box fontSize={12} color="text.secondary">
              {new Date(item.updated_at).toLocaleString()}
            </Box>
          </Box>
        </MenuItem>
      ))}
    </Box>
  )
}

export const NotificationWidget = ({
  onClick,
}: {
  onClick: Dispatch<UnreadChatRoom>
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)

  const { data } = useChatRoomsUnreadList()
  const badgeValue = data ? data.length : 0

  const handleSelect = (item: UnreadChatRoom) => {
    onClick(item)
    handleClose()
  }

  return (
    <div>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Badge badgeContent={badgeValue} color="info">
          <NotificationsIcon fontSize="large" sx={{ color: '#fff' }} />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <NotificationList items={data ?? []} onSelect={handleSelect} />
      </Popover>
    </div>
  )
}
