import { ButtonProps as MuiButtonProps } from '@mui/material'

import { StyledButton } from '@/components/uiParts/Button/BasicButton/style'

export type ButtonProps = Omit<MuiButtonProps, 'variant'> & {
  variant?: MuiButtonProps['variant']
}

export const Button = ({
  variant = 'contained',
  children,
  ...rest
}: ButtonProps) => (
  <StyledButton variant={variant} {...rest}>
    {children}
  </StyledButton>
)
