import { Box, styled } from '@mui/material'

export const StyledAdminSidebar = styled(Box)({
  boxSizing: 'border-box',
  background: '#42526e',
  height: '100vh',
  position: 'fixed',
  zIndex: 80,
  width: '70px',
})

export const StyledAdminSidebarMenuArea = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  margin: '20px 10px 0',
  boxSizing: 'border-box',
  height: '88%',
  overflow: 'hidden',
  '&:hover': {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      height: '0 !important',
      width: '1px !important',
    },
  },
})

export const StyledAdminSidebarMenuItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ isActive }) => ({
  padding: '0 4px',
  boxSizing: 'border-box',
  minHeight: '50px',
  lineHeight: '50px',
  cursor: 'pointer',
  fontSize: '14px',
  display: 'flex',
  background: isActive ? '#f2f3f5' : '',
  color: isActive ? '#42526e' : '#f2f3f5',
  '&:hover': {
    background: '#f2f3f5',
    color: '#42526e',
  },
}))

export const StyledIcon = styled(Box)({
  margin: 'auto 0',
  transform: 'translate(0, 0)',
  transition: 'all 0.2s ease 0s',
})

export const StyledValue = styled(Box)({
  transform: 'scale(0.8) translate(0)',
  transition: 'all 0.1s ease 0s',
  fontSize: '10px',
  lineHeight: '1.4',
  textAlign: 'center',
  whiteSpace: 'nowrap',
})
