export const AppConfig = () => {
  const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY as string
  // ビルド周りの設定に関わるNODE_ENVとは区別している
  const appEnv = process.env.NEXT_PUBLIC_APP_ENV as 'production' | undefined

  return {
    appEnv,
    googleMapsApiKey,
    api: {
      key: process.env.API_KEY as string,
      url: process.env.NEXT_PUBLIC_API_URL as string,
    },
    mapbox: {
      url: process.env.NEXT_PUBLIC_MAPBOX_URL as string,
      token: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN as string,
    },
    chat: {
      url: process.env.NEXT_PUBLIC_CHAT_URL as string,
    },
  }
}
