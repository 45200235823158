/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `COLLECT` - Collect
* `PREPAID` - Prepaid
 */
export type PayerTypeEnum = typeof PayerTypeEnum[keyof typeof PayerTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayerTypeEnum = {
  COLLECT: 'COLLECT',
  PREPAID: 'PREPAID',
} as const;
