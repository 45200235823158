import { Box, Popper, Fade, Paper, Stack, Divider } from '@mui/material'
import { useRouter } from 'next/router'
import { signOut } from 'next-auth/react'
import { useTranslation } from 'react-i18next'

import { useUserButton } from '@/components/organisms/common/Header/UserItemPopper/hooks'
import { StackColumn } from '@/components/uiParts/Stack/Column'
import { StackRow } from '@/components/uiParts/Stack/Row'
import { ADMIN_ROOT_URL, USER_ROOT_URL } from '@/constants/constants'
import UserIcon from '@/icons/user_icon.svg'
import { useUsersMeRetrieve } from '@api'

// アカウント管理、ログアウトボタン
const UserActionButton = ({
  handleClick,
  fontColor,
  children,
}: {
  handleClick: () => void
  fontColor?: string
  children: string
}) => (
  <Box
    onClick={handleClick}
    sx={{
      cursor: 'pointer',
      ':hover': { backgroundColor: 'base.dark' },
    }}
  >
    <Box sx={{ color: fontColor, fontSize: '14px', p: '16px 20px' }}>
      {children}
    </Box>
  </Box>
)

export const UserItemPopper = ({
  navigatePath = '/user/mypage',
}: {
  navigatePath?: string
}) => {
  const { open, anchorEl, openPopper, closePopper, stopCloseTimer } =
    useUserButton()
  const { data: user } = useUsersMeRetrieve()
  const router = useRouter()
  const { t } = useTranslation()
  const isAdminPath = router.asPath.includes('/admin/')

  const logout = () => signOut({ callbackUrl: '/login' })

  return (
    <Box
      sx={{ cursor: 'pointer', width: '36px', height: '36px' }}
      onClick={openPopper}
      onMouseOut={closePopper}
    >
      <UserIcon />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        onMouseOver={stopCloseTimer}
        sx={{
          marginTop: '8px!important',
          borderRadius: '8px',
          zIndex: '100',
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Box p={2} onClick={() => router.push('/mypage')}>
                <StackColumn spacing={2} sx={{ lineHeight: '1.0' }}>
                  <Box sx={{ fontWeight: '700' }}>{t('account')}</Box>
                  <StackRow>
                    <UserIcon />
                    <StackColumn>
                      <Box sx={{ fontSize: '14px', fontWeight: '500' }}>
                        {user?.username || '-'}
                      </Box>
                      <Box
                        sx={{
                          color: 'text.secondary',
                          fontSize: '10px',
                        }}
                      >
                        {user?.email || '-'}
                      </Box>
                    </StackColumn>
                  </StackRow>
                </StackColumn>
              </Box>
              <Divider />
              <Stack>
                {user?.is_portrich && (
                  <UserActionButton
                    handleClick={() =>
                      router.push(isAdminPath ? USER_ROOT_URL : ADMIN_ROOT_URL)
                    }
                  >
                    {isAdminPath ? 'User' : 'Admin'}
                  </UserActionButton>
                )}
                <UserActionButton handleClick={() => router.push(navigatePath)}>
                  {t('account_management')}
                </UserActionButton>
                <UserActionButton fontColor="alert.main" handleClick={logout}>
                  {t('logout')}
                </UserActionButton>
              </Stack>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  )
}
