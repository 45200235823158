/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type ShipmentsTasksByCategoriesListTaskType = typeof ShipmentsTasksByCategoriesListTaskType[keyof typeof ShipmentsTasksByCategoriesListTaskType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentsTasksByCategoriesListTaskType = {
  Sub_Task: 'Sub Task',
  Task: 'Task',
} as const;
