/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `PORTRICH` - ポートリッチ支払
* `USER_COMPANY` - 荷主支払
 */
export type OceanFreightChargePayerEnum = typeof OceanFreightChargePayerEnum[keyof typeof OceanFreightChargePayerEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OceanFreightChargePayerEnum = {
  PORTRICH: 'PORTRICH',
  USER_COMPANY: 'USER_COMPANY',
} as const;
