import { useState, useRef, MouseEvent } from 'react'

const POPPER_CLOSE_DURATION_MS = 400

export const useUserButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)

  const timer = useRef<null | NodeJS.Timeout>(null)
  /**
   * Popperを閉じるまでのsetTimeoutをクリア
   * Hoverが外れてからも、再度Popperが出るアクションを行った際にTimerをクリアして、閉じないよう制御する
   */
  const stopCloseTimer = () => {
    if (!timer.current) return
    clearInterval(timer.current)
    timer.current = null
  }

  const openPopper = (e: MouseEvent<HTMLElement>) => {
    stopCloseTimer()
    setAnchorEl(e.currentTarget)
    setOpen(true)
  }
  /**
   * POPPER_CLOSEDURATION_MSミリ秒後に閉じる
   * この間にstopCloseTimerが走ると、中断する。
   */
  const closePopper = () => {
    if (timer.current !== null) return
    timer.current = setTimeout(() => setOpen(false), POPPER_CLOSE_DURATION_MS)
  }

  return {
    open,
    anchorEl,
    openPopper,
    closePopper,
    stopCloseTimer,
  }
}
