import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

/**
 * progress
 * 画面全体で適応したい場合はそのまま使用する。
 * 要素内のみで適応したい場合は、親要素に{ position: 'relative' }を設定する。
 */
export const Loading = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 9999,
    }}
  >
    <CircularProgress size={60} />
  </Box>
)
