/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `EXIST` - あり
* `NONE` - なし
* `TBA` - 後報
 */
export type ExistDoIdNumberEnum = typeof ExistDoIdNumberEnum[keyof typeof ExistDoIdNumberEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExistDoIdNumberEnum = {
  EXIST: 'EXIST',
  NONE: 'NONE',
  TBA: 'TBA',
} as const;
