/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `AM` - AM希望
* `PM` - PM希望
* `WITHIN_SAME_DAY` - 当日中
* `TIME_SPECIFIED` - 時間指定あり
 */
export type DeliveryTimeSpanEnum = typeof DeliveryTimeSpanEnum[keyof typeof DeliveryTimeSpanEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryTimeSpanEnum = {
  AM: 'AM',
  PM: 'PM',
  WITHIN_SAME_DAY: 'WITHIN_SAME_DAY',
  TIME_SPECIFIED: 'TIME_SPECIFIED',
} as const;
