/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  Dispatch,
  ReactElement,
  useMemo,
  useReducer,
} from 'react'

type ConditionsStateType = Record<string, unknown>
// TODO: 呼び出し元で使用する型が決まったら型を指定する
type ConditionsActionType = {
  type: 'UPDATE_CONDITIONS'
  payload: {
    key: any
    value: any
  }
}

// ページ単位の検索条件保持
const initialState: ConditionsStateType = {}
const defaultDispatch: Dispatch<ConditionsActionType> = () => initialState
const defaultValue = {
  ...initialState,
  conditionsDispatch: defaultDispatch,
}

export const ConditionsContext = createContext(defaultValue)

const reducer = (state: ConditionsStateType, action: ConditionsActionType) => {
  switch (action.type) {
    case 'UPDATE_CONDITIONS': {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    }
    default:
      return state
  }
}

/**
 * reducerとcontextを子コンポーネントに渡す
 * @param children 子コンポーネント
 * @returns 子コンポーネント
 */
export const ConditionsContextProvider = ({
  children,
}: {
  children: ReactElement
}) => {
  const [conditions, conditionsDispatch] = useReducer(reducer, initialState)

  const context = useMemo(
    () => ({
      conditions,
      conditionsDispatch,
    }),
    [conditions, conditionsDispatch],
  )

  return (
    <ConditionsContext.Provider value={context}>
      {children}
    </ConditionsContext.Provider>
  )
}
