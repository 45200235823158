/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type PurchaseManagementsSeaFreightsListShippingMethod = typeof PurchaseManagementsSeaFreightsListShippingMethod[keyof typeof PurchaseManagementsSeaFreightsListShippingMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseManagementsSeaFreightsListShippingMethod = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
