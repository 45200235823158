import { AxiosError } from 'axios'
import { signOut } from 'next-auth/react'
import { useContext } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { FieldError, FieldValues, Path, UseFormReturn } from 'react-hook-form'

import { SnackbarContext } from '@/contexts/Snackbar'
import { isHttpStatusError } from '@/utils/apiUtils'

const CLIENT_CANCELED = [AxiosError.ERR_CANCELED, AxiosError.ECONNABORTED]

type FormErrors<FormData extends FieldValues> = Record<
  Path<FormData>,
  FieldError
>

export const useHandleAxiosError = <FormData extends FieldValues>(
  props: { form?: UseFormReturn<FormData> } = {},
) => {
  const { form } = props
  const { showBoundary } = useErrorBoundary()
  const { snackbarDispatch } = useContext(SnackbarContext)

  /** API Responseを受け取って異常 or 準正常を判別し、それぞれ処理する */
  const handleAxiosError = (err: unknown) => {
    // キャンセル系は無視
    if (err instanceof AxiosError && CLIENT_CANCELED.includes(err.code ?? '')) {
      return undefined
    }

    // 異常系エラー
    if (
      !(err instanceof AxiosError) ||
      isHttpStatusError(err.response?.status)
    ) {
      showBoundary(err) // ErrorBoundaryで処理
    }

    // 認証エラー
    else if (err.response?.status === 401) {
      signOut()
    }

    // 準正常系エラー
    else {
      // バリデーションエラーをフォームにバインドする
      if (form && typeof err.response?.data === 'object') {
        const fieldValues = form.getValues()
        const validationErrors: FormErrors<FormData> =
          {} as FormErrors<FormData>
        Object.entries(err.response.data).forEach(([name, messages]) => {
          // フォームに存在するフィールドのみに絞る
          if (name in fieldValues) {
            validationErrors[name as Path<FormData>] = {
              type: 'manual',
              message: String(messages),
            }
          } else {
            // TODO: フォームに無いフィールドのエラーはどう扱うべきか？
          }
        })
      }

      const { detail } = err.response?.data || 'error'

      snackbarDispatch({
        message: detail,
        severity: 'error',
      })
    }
    return undefined
  }

  return { handleAxiosError }
}
