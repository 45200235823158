import { Box } from '@mui/material'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import {
  StyledHeader,
  StyledHeaderRightArea,
} from '@/components/organisms/admin/common/Header/style'
import { UserItemPopper } from '@/components/organisms/common/Header/UserItemPopper'
import { LanguageSwitcher } from '@/components/organisms/common/Language'
import { NotificationWidget } from '@/components/organisms/common/Notification'
import { ADMIN_ROOT_URL } from '@/constants/constants'

export const Header = () => {
  const router = useRouter()

  return (
    <StyledHeader id="portrich-global-header" className="wovn-embedded-widget">
      <Box display="flex" alignItems="center" gap="24px">
        <div>
          <Link href={ADMIN_ROOT_URL}>
            <Image
              src="/images/portrich_logo_white.svg"
              width={142}
              height={18}
              alt="logo"
            />
          </Link>
        </div>
      </Box>
      {/* {AppConfig().appEnv !== 'production' && (
      <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
        Develop or Test environment
      </Box>
    )} */}
      <StyledHeaderRightArea>
        <Box className="wovn-embedded-widget-anchor" />
        <LanguageSwitcher />
        <NotificationWidget
          onClick={(room) => router.push(`/admin/shipment/${room.shipment}`)}
        />
        <UserItemPopper navigatePath="/admin/mypage" />
      </StyledHeaderRightArea>
    </StyledHeader>
  )
}
