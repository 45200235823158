/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `1` - Original
* `2` - Waybill
* `3` - Surrender
 */
export type DocumentsEnum = typeof DocumentsEnum[keyof typeof DocumentsEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentsEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;
