/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `BEGINNER` - 低
* `INTERMEDIATE` - 中
* `ADVANCED` - 高
 */
export type TradeKnowledgeLevelEnum = typeof TradeKnowledgeLevelEnum[keyof typeof TradeKnowledgeLevelEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TradeKnowledgeLevelEnum = {
  BEGINNER: 'BEGINNER',
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED',
} as const;
