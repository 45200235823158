import { ReactElement } from 'react'
import { SWRConfig } from 'swr'

import { useHandleAxiosError } from '@/hooks/common/useHandleAxiosError'

/** SWRのグローバル設定 */
export const SwrWrapper = ({ children }: { children: ReactElement }) => {
  const { handleAxiosError } = useHandleAxiosError()
  const options = { onError: handleAxiosError }

  return <SWRConfig value={options}>{children}</SWRConfig>
}
