import { Popper, Fade, Box } from '@mui/material'
import { useRouter } from 'next/router'
import { DispatchWithoutAction } from 'react'

import {
  StyledPaper,
  StyledLinkBox,
} from '@/components/organisms/user/header/AppBar/AppBarItemPopper/style'

type Props = {
  open: boolean
  items: { label: string; path: string }[]
  anchorEl: HTMLElement | null
  stopCloseTimer: DispatchWithoutAction
  closePopper: DispatchWithoutAction
}

export const AppBarItemPopper = (props: Props) => {
  const { open, items, anchorEl, stopCloseTimer, closePopper } = props
  const router = useRouter()

  return (
    <Popper
      placement="bottom-start"
      transition
      open={open}
      anchorEl={anchorEl}
      onMouseOver={stopCloseTimer}
      onMouseOut={closePopper}
      sx={{
        marginTop: '8px!important',
        borderRadius: '8px',
        zIndex: '100',
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <StyledPaper>
            {items.map((item, index: number) => (
              <StyledLinkBox key={index} onClick={() => router.push(item.path)}>
                <Box sx={{ ml: '12px' }}> {item.label}</Box>
              </StyledLinkBox>
            ))}
          </StyledPaper>
        </Fade>
      )}
    </Popper>
  )
}
