/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type ShipmentsListBookingStatus = typeof ShipmentsListBookingStatus[keyof typeof ShipmentsListBookingStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentsListBookingStatus = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;
