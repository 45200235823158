/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type ShippingSchedulesSearchRetrieveDateRange = typeof ShippingSchedulesSearchRetrieveDateRange[keyof typeof ShippingSchedulesSearchRetrieveDateRange];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShippingSchedulesSearchRetrieveDateRange = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
} as const;
