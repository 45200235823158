/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `0` - ステータスなし
* `1` - Booking 中
* `2` - Booking 完了
* `3` - Booking 不成立
* `4` - Booking キャンセル
 */
export type BookingStatusEnum = typeof BookingStatusEnum[keyof typeof BookingStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BookingStatusEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;
