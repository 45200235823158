import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { getSession } from 'next-auth/react'
import qs from 'qs'

import { AppConfig } from '@/config/appConfig'
import { decode } from '@/utils/jwt'

const { api } = AppConfig()
const baseURL = api.url

// 使ってないかも
export default axios.create({ baseURL })

export const ignoreNotFound = (e: unknown) => {
  if (e instanceof AxiosError && e.response?.status === 404) {
    return
  }
  throw e
}

// middlewareでrewriteするのでbaseURLは設定しない
export const axiosPrivate = axios.create({
  headers: {
    'Content-Type': 'application/json',
    ...(process.env.NODE_ENV === 'test' && { api_key: 'TEST_API_KEY' }),
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'comma' })
  },
})

// getSessionのレスポンスがあまりに遅いのでャッシュしている
// TODO: 正式リリースまでにまともな対応をする
let accessToken = ''
const fetchAccessToken = async () => {
  if (process.env.NODE_ENV === 'test') return 'test'
  try {
    const jwt = decode(accessToken)
    if (jwt.exp > Date.now() / 1000) {
      return accessToken
    }
    throw new Error('fetch')
  } catch (e) {
    const session = await getSession()
    if (session?.accessToken) {
      accessToken = session.accessToken
    }
    return accessToken
  }
}

// リクエスト前に実行。headerに認証情報を付与する
axiosPrivate.interceptors.request.use(
  async (request) => {
    if (request.headers) {
      const token = await fetchAccessToken()
      if (token) {
        request.headers.Authorization = `Bearer ${token}`
      }
    }
    return request
  },
  (error) => {
    throw new Error(error)
  },
)

// orvalで生成するSWRのHooksで使用
export const swrFetcher = async <T>(
  config: AxiosRequestConfig,
  { multipart } = {
    /** ファイルのアップロードなどBlobを送信するときはtrueを設定 */
    multipart: false,
  },
): Promise<T> => {
  const headers = config.headers ?? {}
  headers['Content-Type'] = multipart
    ? 'multipart/form-data'
    : 'application/json'
  return axiosPrivate({ ...config, headers }).then((res) => res.data as T)
}
