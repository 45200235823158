/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type SchemaRetrieveFormat = typeof SchemaRetrieveFormat[keyof typeof SchemaRetrieveFormat];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SchemaRetrieveFormat = {
  json: 'json',
  yaml: 'yaml',
} as const;
